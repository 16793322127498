<template>
  <div>
    <app-header />

    <div v-if="status.code_is_valid" class="uk-container uk-margin-medium">
      <div v-if="status.auth" class="uk-grid">
        <div class="uk-width-1-2@m uk-align-center">
          <div
            class="uk-card uk-card-default uk-card-body"
            v-if="status.can_subscribe"
          >
            <h3>Subscribe to signals</h3>
            <p>Hello {{ user.name }},</p>
            <p>
              Get a free subscription for premium signals directly to your email
              and though our telegram channel.
            </p>
            <a
              href="#"
              class="uk-button uk-button-primary"
              @click.prevent="activate"
              >Activate {{ status.months }} month{{
                status.months == 1 ? "" : "s"
              }}
              free</a
            >
            <p></p>
          </div>
          <div
            class="uk-card uk-card-default uk-card-body"
            v-if="! status.can_subscribe"
          >
            <h3>Hello {{ user.name }},</h3>
            <p>You aready have an active signals subscription.</p>
            <router-link :to="{ name: 'guest.signals' }" class="uk-button uk-button-primary">Check my subscription</router-link>
          </div>
        </div>
      </div>
      <div v-if="!status.auth" class="uk-grid">
        <div class="uk-width-2-3@m uk-align-center">
          <div class="uk-card uk-card-default uk-card-body">
            <h3>Create an account to get free signals for {{ status.months }} month{{ status.months == 1 ? '' : 's' }}</h3>
            <p>
              Get a free subscription for premium signals directly to your email
              and though our telegram channel.
            </p>
            <form @submit.prevent="submit">
              <div class="uk-grid">
                <div class="uk-width-1-1@m uk-margin-small">
                  <label for="">Name</label>
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon"
                      ><i class="fa fa-user" style="margin-right: 20px"></i
                    ></span>
                    <input
                      class="uk-input uk-form-large"
                      type="text"
                      required
                      v-model="new_user.name"
                      placeholder="Full Name"
                    />
                    <template v-if="error != null"
                      ><span class="uk-text-danger" v-if="error.errors.name">{{
                        error.errors.name[0]
                      }}</span></template
                    >
                  </div>
                </div>
                <div class="uk-width-1-2@m uk-margin-small">
                  <label for="">Phone Number</label>
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon"
                      ><i class="fa fa-phone" style="margin-right: 20px"></i
                    ></span>
                    <input
                      type="text"
                      id="phone"
                      placeholder="Phone Number"
                      v-model="new_user.phone_number"
                      class="uk-input uk-form-large"
                    />
                    <template v-if="error != null"
                      ><span
                        class="uk-text-danger"
                        v-if="error.errors.phone_number"
                        >{{ error.errors.phone_number[0] }}</span
                      ></template
                    >
                  </div>
                </div>
                <div class="uk-width-1-2@m uk-margin-small">
                  <label for="">Email</label>
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon"
                      ><i class="fa fa-envelope" style="margin-right: 20px"></i
                    ></span>
                    <input
                      class="uk-input uk-form-large"
                      type="text"
                      required
                      v-model="new_user.email"
                      placeholder="Email"
                    />
                    <template v-if="error != null"
                      ><span class="uk-text-danger" v-if="error.errors.email">{{
                        error.errors.email[0]
                      }}</span></template
                    >
                  </div>
                </div>
                <div class="uk-width-1-2@m uk-margin-small">
                  <label for="">Password</label>
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon"
                      ><i class="fa fa-lock" style="margin-right: 20px"></i
                    ></span>
                    <input
                      class="uk-input uk-form-large"
                      type="password"
                      required
                      v-model="new_user.password"
                      placeholder="Password"
                    />
                    <template v-if="error != null"
                      ><span
                        class="uk-text-danger"
                        v-if="error.errors.password"
                        >{{ error.errors.password[0] }}</span
                      ></template
                    >
                  </div>
                </div>
                <div class="uk-width-1-2@m uk-margin-small">
                  <label for="">Confirm Password</label>
                  <div class="uk-inline uk-width-1-1">
                    <span class="uk-form-icon"
                      ><i class="fa fa-lock" style="margin-right: 20px"></i
                    ></span>
                    <input
                      class="uk-input uk-form-large"
                      type="password"
                      required
                      v-model="new_user.password_confirmation"
                      placeholder="Confirm Password"
                    />
                  </div>
                </div>
              </div>
              <div class="uk-margin uk-text-center">
                <button
                  class="
                    uk-button uk-button-primary uk-button-large uk-width-1-2
                  "
                >
                  Get free signals
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="! status.code_is_valid" class="uk-container uk-margin-medium">
      <div class="uk-grid">
        <div class="uk-width-1-2@m uk-align-center">
          <div class="uk-card uk-card-default uk-card-body">
            <h3>Invalid Promotion Code</h3>
            <p>Contat us for more informatuon</p>
            <router-link :to="{ name: 'redirect' }" class="uk-button uk-button-primary">Go Home</router-link>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "./public/components/AppHeader";
import AppFooter from "./public/components/AppFooter";
import intlTelInput from "intl-tel-input";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  mounted() {
    this.fetch();
  },

  data() {
    return {
      status: [],
      new_user: {},
      error: null,
      iti: "",
    };
  },

  methods: {
    loadPhone() {
      let input = document.querySelector("#phone");
      this.iti = intlTelInput(input, {
        preferredCountries: ["ke", "us"],
        separateDialCode: true,
      });
    },

    fetch() {
      this.$loader.start("full");

      this.$axios
        .get(`/api/v1/promotions/${this.$route.params.code}`)
        .then((response) => {
          this.status = response.data;
          this.$loader.stop();

          if(response.data.code_is_valid && !response.data.auth && response.data.can_subscribe) {
            setTimeout(() => {
              this.loadPhone()
            }, 1000)
          }
        });
    },

    activate() {
      this.$loader.start("full");

      this.$axios
        .post(`/api/v1/promotions/${this.$route.params.code}`)
        .then(() => {
          this.$router.push({ name: "public.signals" });
          this.$loader.stop();
        });
    },

    submit() {
      this.$loader.start("full");
      this.error = null;
      this.user.phone_number = `${this.iti.getSelectedCountryData().dialCode}${
        this.user.phone_number
      }`;

      this.$axios
        .post(`/api/v1/promotions/${this.$route.params.code}/create`, this.new_user)
        .then(() => {
          this.$router.push({ name: "check-email" });
          this.$loader.stop();
        }).catch((error) => {
          let response = error.response;
          this.$loader.stop();

          if (response.status == 422) {
            this.error = response.data;
          } else {
            this.error = {
              message: "An error occurred. Please try again later",
            };
          }
        });
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>